@use "../settings" as *;

body {
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  overflow: auto;
}

body {
  .page {
    border: 1px solid red;
    display: flex;
    flex-direction: column;
  }
    background: linear-gradient(45deg, $main-logo-color, $light-logo-color, $main-contrast-color);
    background-repeat: no-repeat!important;

    height: 100%;
    width: 100%;
    margin: 0!important;
    padding: 0!important;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }

}

