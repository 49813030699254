@use "../settings" as *;

section {
  position: relative;
  width: 100%;

  .container {
    padding-top: $size-18;
    padding-bottom: $size-18;
  }
}

.dark-purple-background {background-color: $dark-purple-background;}
.dark-green-background {background-color: $dark-green-background;}
.light-green-background {background-color: $light-logo-color;}

main {
  .page-heading {
    margin-bottom: $size-12;
  }
}

#main-logo {
  width: 70%;
  height: auto;
  max-width: 70%;
  @media screen and (max-width: $tablet-width) {
    width: 90% !important;
    max-width: 90% !important;
  }
}

.main-image-row {
  gap: $size-10;
}

#main-video {
  width: 80%;
  height: auto;
  max-width: 80%;
}

.button-row {
  gap: $size-18;
  justify-content: space-evenly!important;
  @media screen and (max-width: $tablet-width) {
      flex-direction: column-reverse!important;
      gap: $size-10!important;
  }
}

.case-rows {gap: $size-12;}

.case-row {
  gap: 5rem;
  @media screen and (max-width: $mobile-width) {
    gap: 0!important;
  }
}

.case-row-img {height: 180px;}

.case-customer-row {
  align-items: flex-start;
  @media screen and (max-width: $tablet-width) {
      flex-direction: column!important;
      align-items: center!important;
  }
}

#nff-info {
  @media screen and (max-width: $tablet-width) {
      flex-direction: column!important;
      align-items: center!important;
  }
}


.about-row {
  .icons {
      img {
      height: auto;
      max-width: 50%;
      }
  }
}

.contact-row {
  gap: 15rem;
  @media screen and (max-width: $desktop-width) {
    gap: 5rem!important;
  }
  @media screen and (max-width: $tablet-width) {
    flex-direction: column!important;
    align-items: flex-start!important;
  }
}

#card-example {
  height: 300px;
  transform: rotate(25deg);
  margin: 10px;
  @media screen and (max-width: $desktop-width) {
    height: 280px!important;
    margin-top: 8rem!important;
  }
}
