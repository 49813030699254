.card-page {
    display: flex;
    justify-content: start;
    align-items: start;
    height: 100vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    font-size: 16px;
    background-color: white;
    background-image: none;
}

:root {
  --qr-size: 280px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
