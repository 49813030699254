@use "../settings" as *;


.link-button {
  padding-left: 55px;
  padding-top: 8px;
  color: $siqr-white-color;
  border: 1px solid $main-contrast-color;
  border-radius: 1rem;
  background-color: $main-contrast-color;
  font-size: 16px;
  width: 200px;
  height: 41px;
  text-decoration: none;
}

.nt-button {
  font-size:1.2rem;
  display:inline;
  white-space:nowrap;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 1rem;
  padding: 10px;
  width: 200px;
  color: $siqr-black-color;

  img {
    height: calc(1.6 * 1.2rem);
    vertical-align:-0.4em;
    width:auto;
    display:inline;
    position:relative;
    max-width:100%;
    margin-left: 5px;
  }
}
