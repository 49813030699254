.phone {
    .phone-content {
        border: 2px solid #00205b;

        hgroup {
            background-color: #00205b;
            color: white;
            border: 1px solid green;
        }
        .content {
            p.dommernr {
                margin: 0;
                color: #666;
            }
        }
    }
}

.rekruttdommer {
    hgroup{
      background-color: #063e2a!important;
    }
}