@use "../settings" as *;

.flex-row-column {
    display: flex;
    flex-direction: column;
}

.flex-row-col-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-row-start {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.flex-row-center {
    display: flex;
    flex-direction: row;
    align-items: center;
  @media screen and (max-width: $mobile-width) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
  }
}
