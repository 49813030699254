// colors
$main-logo-color: #38b137 !default;
$light-logo-color: #E1FAE1 !default;
$main-contrast-color: #950EC2 !default;
$main-complement-color: #FFBF11 !default;
$siqr-white-color: #FBFBFB !default;
$siqr-black-color: #000200 !default;
$dark-purple-background: #8F0DBA !default;
$dark-green-background: #1F611F !default;

$c-neutral: #222 !default;
$c-background: #f5f5f5 !default;
$c-db: #303030 !default;

$c-blue: #8fd8ee !default;
$c-blue-medium: #2F95B1 !default;
$c-blue-comp: #508EA1 !default;
$c-blue-dark: #42636E !default;
$c-marked-color: #ff9d00 !default;
$c-highlight-bg-color: #ff9d00 !default;

$c-basecolor: #fff !default;
$c-ok: #3cdbc0 !default;
$c-success: #009b77 !default;
$c-info: #5bc2e7 !default;
$c-warning: #e6a65d !default;
$c-danger: #e45d50 !default;
$c-error: #e45d50 !default;

$c-brand-color: #027dc3 !default;
$c-logo: #334d5a !default;

$c-neutral-0: white !default;
$c-neutral-5: #f2f2f2 !default;
$c-neutral-10: #e6e6e6 !default;
$c-neutral-15: #d9d9d9 !default;
$c-neutral-20: #cccccc !default;
$c-neutral-25: #bfbfbf !default;
$c-neutral-30: #b3b3b3 !default;
$c-neutral-35: #a6a6a6 !default;
$c-neutral-40: #999999 !default;
$c-neutral-45: #8c8c8c !default;
$c-neutral-50: gray !default;
$c-neutral-55: #737373 !default;
$c-neutral-60: #666666 !default;
$c-neutral-65: #595959 !default;
$c-neutral-70: #4d4d4d !default;
$c-neutral-75: #404040 !default;
$c-neutral-80: #333333 !default;
$c-neutral-85: #262626 !default;
$c-neutral-90: #1a1a1a !default;
$c-neutral-95: #0d0d0d !default;
$c-neutral-100: black !default;

// media queries brakepoints
$mobile-width: 544px;
$tablet-width: 768px;
$desktop-width: 992px;
$lg-desktop-width: 1200px;


// gaps
$g-1: 2px;
$g-2: 6px;
$g-3: 15px;
$g-4: 20px;
$g-5: 30px;

$gap: $g-3; // default gap


// border-radius (tailwind defaults)
$rounded-none: 0px;
$rounded-sm: 0.125rem; /* 2px */
$rounded: 0.25rem; /* 4px */
$rounded-md: 0.375rem; /* 6px */
$rounded-lg: 0.5rem; /* 8px */
$rounded-xl: 0.75rem; /* 12px */
$rounded-2xl: 1rem; /* 16px */
$rounded-3xl: 1.5rem; /* 24px */
$rounded-full: 9999px;


// theme..?
$theme-separation-border: 1px dotted $c-neutral-40;
