@use "../vendor" as *;
@use "../settings" as *;

nav.navbar {
    background-color: transparent!important;
    border: none!important;

  a {
    color: $siqr-black-color!important;
    font-size: 1.8rem!important;

  }
  .navbar-header {
    width: 100%;
  }
  .navbar-brand {
      img {
        max-width: 250px;
      }
      @include media-breakpoint-down(md) {
          width: unset!important;
      }
  }
  .navbar-nav {
    display: flex;
    flex-direction: row;
    //gap: 5rem;
    @media screen and (max-width: $lg-desktop-width) {
      //gap: 2rem!important;
      li {
        font-size: 1.6rem!important;
      }
   }
    @media screen and (max-width: $desktop-width) {
      //gap: 1rem!important;
      li {
        font-size: 1.4rem!important;
      }
    }

    li {
      list-style-type: none;
      color: $siqr-black-color;
      font-size: 1.8rem;
    }
  }
}

.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;

  .navbar-right .actions {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 15px 15px;

    a {
      max-width: 200px;
    }
  }
}
