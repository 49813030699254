.solarium {
  .phone {
      position: relative;

      .phone-content {
          hgroup {
              background-color: white;
              color: white;
              border: none;
              padding: 0;
              .header {
                  img {
                      width: 100%;
                  }
              }
          }
      }
  }
}
