.phone {
    position: relative;

    .phone-content {
        height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        overflow: hidden;
        border: 2px solid #00205b; // Dark blue since fotballdommer was first, override it in your own styling

        @media screen and (max-width: 600px) {
          border: none;
        }
        hgroup {
            display: flex;
            align-items: center;
            max-width: 100vw;
            gap: 20px;
            padding-inline: max(4rem, 25vw);
            padding:  1.5rem 2rem;

            .header {
                display: flex;
                align-items: center;
                gap: 1rem;
                img {
                    width: 60px;
                }
            }

            h1 {
                font-size: clamp(1rem, 6vw, 2rem);
                margin: 0;
            }
        }
        hgroup:hover {
            //background-color: #00205bbe; // If you want different hover color
        }

        em {
            font-style: normal;
            font-size: 120%;
            margin-inline: 1rem;
        }

        .content {
            text-align: center;
            padding-inline: 2rem;
            h2 {
                margin-bottom: 0;
            }
        }

        .shield {
            margin-top: 20px;
        }
        .valid {
            color: green;
            font-size: 1.5rem;
            text-align: center;
        }
        .invalid {
            color: red;
            font-size: 1.5rem;
            text-align: center;

            em {
                font-style: normal;
                font-size: 200%;
                margin-inline: 1rem;
            }
        }
        footer {
            padding-block: clamp(5px, 3vw, 2rem) clamp(5px, 5vw, 2rem);
            padding-inline: clamp(5px, 5vw, 2rem);
            background-color: white;
            color: black;

            position: relative;
            height: 180px;
            margin-top: 20px;
            clear: both;
            font-size: 12px;
            line-height: 110%;
            text-align: center;
            letter-spacing: 0.2em;
        }
    }
}
