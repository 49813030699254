@use "../vendor" as *;
@use "../settings" as *;

footer.page-footer {
  box-sizing: border-box;
  position: relative;
  background-color: #000;
  margin-top: 50px;
  font-size: 12px;

  .footer-content {
    min-height: 6cm;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    font-family: Tahoma, Verdana, Arial, Helvetica, sans-serif;
    letter-spacing: .2em;
    color: $siqr-white-color !important;

    a, a:hover {
      color: $siqr-white-color;
    }

    .footer-label {
      color: #b4b4b4
    }

    .norsktest-footer {
      display: flex;
      flex-direction: row;
      align-items: flex-end;

      .contact-info {
        padding: 0 1cm;
        font-variant: small-caps;
        font-size: 12px;

        .info-list {
          list-style-type: none!important;
        }
      }

      .created-by {
        font-weight: 700;
        line-height: 190%;
        text-align: left;
        border-left: 1px solid #b4b4b4;
        padding: .5cm 1cm;
        font-size: 12px;

        a {
          font-size: inherit;
        }
      }

    }
  }

  .legal {
    position: absolute;
    left: 40px;
    bottom: 20px;

    a, footer.page-footer .legal a:hover {
      color: #b4b4b4;
      margin-right: 1em;
      font-size: 12px;
    }
  }
}

