@use "../settings" as *;


.inverse-text-color {
  color: $siqr-white-color;
  a {
    color: $siqr-white-color;
  }
  a:hover {
    color: $siqr-white-color;
  }
}

.p16 {
  font-size: 16px;
}

.icon-text {
  margin: 0;
  font-size: 2.5rem;
  text-align: center;
}

.p-h2 {
  font-size: 30px;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 30px;
  margin-bottom: 15px;
}

@media screen and (max-width: $lg-desktop-width) {
  .icon-text {
    font-size: 1.8rem!important;
  }
}

@media screen and (max-width: $mobile-width) {
  .icon-text {
      font-size: 1.2rem!important;
    }
}
